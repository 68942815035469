.center {
  margin: auto;
  width: 80% ;
  padding: 10px;
}

.map-container {
  height: 45vh ;
  width: 35vw ;
 }

 @import "rsuite/dist/rsuite.css";